import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import email from "../images/email.svg"
import openGpaphImage from "../images/opengraph/main.jpg"
import { navigate } from "gatsby"
import fetch from 'node-fetch'


function ContactUs () {

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [message, setMessage] = useState('');
  const [doNotEmail, setdoNotEmail] = useState(false);
  

  const [isFname, setIsFname] = useState(false);
  const [isLname, setIsLname] = useState(false);
  const [isCName, setIsCName] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isMessage, setIsMessage] = useState(false);

  const [fnameError, setFnameError] = useState('');
  const [lnameError, setLnameError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [companyEmailError, setCompanyEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [allowSubmit, setAllowSubmit] = useState(false);

  const validateFname = (e) => {
    if (e.target.value.length > 30) {
      setFnameError('Max 30 symbols is allowed');
      setIsFname(false);
    } else if (e.target.value.length > 0 && e.target.value.length <= 30) {
      setFnameError('');
      setIsFname(true);
      setFname(e.target.value)
    } else {
      setIsFname(false);
      setFname(e.target.value)
    }    
  }

  const validateLname = (e) => {
    if (e.target.value.length > 30) {
      setLnameError('Max 30 symbols is allowed');
      setIsLname(false);
    } else if (e.target.value.length > 0 && e.target.value.length <= 30) {
      setLnameError('');
      setIsLname(true);
      setLname(e.target.value)
    } else {
      setLnameError('');
      setIsLname(false);
      setLname(e.target.value)
    }    
  }

  const validateCname = (e) => {
    if (e.target.value.length > 40) {
      setCompanyNameError('Max 40 symbols allowed');
      setIsCName(false)
    } else if (e.target.value.length > 0 && e.target.value.length <= 40) {
      setCompanyNameError('');
      setIsCName(true)
      setCompanyName(e.target.value)
    } else {
      setCompanyNameError('');
      setCompanyName(e.target.value)
      setIsCName(false)
    }    
  }

  const validateEmailPattern = () => {
    if (companyEmail.length !== 0) {
      if (ValidateEmail(companyEmail)) {
        setCompanyEmailError('');
        setIsEmail(true);
      } else {
        setCompanyEmailError('Your email is incorrect!');
        setIsEmail(false);
      }
    } else {
      setCompanyEmailError('');
      setIsEmail(false);
    }
  }

  const validateEmailLen = (e) => {
    if (e.target.value.length > 40) {
      setCompanyEmailError('Max 40 symbols allowed');
      setIsEmail(false);
    } else {
      setCompanyEmailError('');
      setCompanyEmail(e.target.value)
    }
  }

  const validateMessage = (e) => {
    if (e.target.value.length > 500) {
      setMessageError('Max 500 symbols allowed');
      setIsMessage(false);
    } else if (e.target.value.length > 0 && e.target.value.length <= 500) {
      setMessageError('');
      setIsMessage(true);
      setMessage(e.target.value)
    } else {
      setMessageError('');
      setIsMessage(false);
      setMessage(e.target.value)
    }    
  }

  useEffect(() => {
    if (isFname && isLname && isCName && isEmail && isMessage) {
      setAllowSubmit(true);
    } else { 
      setAllowSubmit(false);
    }
  }, [isFname, isLname, isCName, isEmail, isMessage])

  function ValidateEmail(email) {
    if (/[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/.test(email)){
      return (true)
    }
    return (false)
  }

  const handleDoNotEmail = (e) => {
    if (e.target.checked) {
      setdoNotEmail(true)
    } else {
      setdoNotEmail(false)
    }
  }


  function validateBalancer () {
    if (document.getElementById('balancer').value.length === 0) {
      return (true)
    } else {
      return (false)
    }
  }

  const sendData = (e) => {
    e.preventDefault();
    let dataObject = {};
    dataObject['email'] = companyEmail;
    dataObject.metadata = {};
    dataObject.metadata.firstName = fname;
    dataObject.metadata.lastName = lname;
    dataObject.metadata.company = companyName;
    dataObject.metadata.forbidEmailTargeted = doNotEmail ? false : true;
    dataObject.event = {};
    dataObject.event.message = `Contact Us message: ${message}`;
    dataObject.event.name = 'To reply Iamcore Contact us';
    dataObject.event.activityDuration = '0d';
    const formDataInJson = JSON.stringify(dataObject);

    if (validateBalancer()) {
      let url = 'https://sales-marketing-service.kaatech.com/api/v1/submit-event';
      console.log(url)
      fetch(url, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: formDataInJson
        })
        .then(() => {
          navigate('/thanks/');
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      navigate('/thanks/');
    }


    const params = new URLSearchParams();
    params.append('first_name', fname);
    params.append('last_name', lname);
    params.append('email', companyEmail);
    params.append('company', companyName);
    params.append('do_not_email', doNotEmail ? 'true' : 'false');
    params.append('message', message);
    const body = params.toString();

    if (validateBalancer()) {
      fetch('https://go.pardot.com/l/359721/2022-11-17/kcyqx', {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body
        })
        .then()
        .catch(() => {
          navigate('/thanks/');
        });
        // '/thanks/' is located in error block because pardot saves data but return error because of it redirect url option
    } else {
      navigate('/thanks/');
    }
  }
  
  return (
    <Layout>

    <section className="contact-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-lg-7 col-12 contact-us-greatings">
                <h1>Hey there!<br/> How can we help you today?</h1>
                <p className="intro_p">Tell us what we can do and we will get back to you as soon as possible.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="contact-form-wrapper shadow">

              <form onSubmit={sendData}>
                <div className="row">
                  <div className="col-lg-6 col-12 input-wrapper">
                    <input type="text" className="form-input" name="first_name" value={fname || ''} onChange={validateFname} placeholder="First name*" />
                    <div id="first_name_error" className="form-text">{fnameError}</div>
                  </div>
                  <div className="col-lg-6 col-12 input-wrapper">
                    <input type="text" className="form-input" name="last_name" value={lname || ''} onChange={validateLname} placeholder="Last name*" />
                    <div id="last_name_error" className="form-text">{lnameError}</div>
                  </div>
                  <div className="col-12 input-wrapper">
                    <input type="text" className="form-input" name="email" value={companyEmail || ''} onChange={validateEmailLen} onBlur={validateEmailPattern} placeholder="Company email*" />
                    <div id="company_email_error" className="form-text">{companyEmailError}</div>
                  </div>
                  <div className="col-12 input-wrapper">
                    <input type="text" className="form-input" name="company" value={companyName || ''} onChange={validateCname} placeholder="Company name*" />
                    <div id="company_name_error" className="form-text">{companyNameError}</div>
                  </div>
                  <div className="col-12">
                    <textarea type="textarea" rows="1" className="form-input" name="message" value={message || ''} onChange={validateMessage} placeholder="Message" />
                    <div id="message_error" className="form-text">{messageError}</div>
                  </div>
                  <div className="col-12">
                    <input type="hidden" className="form-input" name="balancer" id="balancer" value="" />
                  </div>

                  <div className="col-12">
                    <div className="d-flex checkbox-wrapper">
                        
                          <input className="form-check-input p-2" name="do_not_email" type="checkbox" onChange={handleDoNotEmail} value={doNotEmail} id="do_not_email"/>
                          <span className="form-check-label" forhtml="do_not_email">
                          I would like to be updated on the latest news, event announcements, and premium content.
                          </span>
                    </div>
                  </div>

                  { allowSubmit ?
                  <button type="submit" className="btn btn-bright" >
                    <img src={email} alt='email_icon' />
                    Contact us
                  </button>
                  : 
                  <button type="submit" className="btn btn-bright" disabled>
                    <img src={email} alt='email_icon' />
                    Contact us
                  </button>
                  }

                </div>
              </form>


            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
  )
}

export const Head = () => <Seo title="Contact Us" description="We would love to hear from you. Contact our team directly to share your experience and challenges of multi-tenant authorization, ore request a quote for your organization." image={openGpaphImage} />

export default ContactUs
